body, div, dl, dt, dd, ul, ol, li,
h1, h2, h3, h4, h5, h6, pre, code,
form, fieldset, legend, input, button,
textarea, p, blockquote, th, td {
    margin: 0;
    padding: 0;
    border:0;
}
html {
    -ms-touch-action: manipulation;
        touch-action: manipulation; /*避免浏览器300ms延时问题，支持iOS safari 10.3+*/
}

body{
  height: auto !important;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset, img {border: 0;}
li {list-style: none;}
/*@purpose To enable resizing for IE */
/*@branch For IE6-Win, IE7-Win */
input, button, textarea, select {
    *font-size: 1em;
}
/* Remove special stylesheet in firefox */
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: none;
    padding: 0;
}


/*
[BaseContents]

        0- Global
        1- Links
        2- Form
*/

/* =0 Global
---------------------------------------------------------------------------------------------------------------------*/
.fl {
    float:left;
}
.fr {
    float:right;
}
.clear {
    clear:both;
}
/* Clearfix */
.clearfix:after {
    clear:both;
    content:" ";
    display:block;
    font-size:0;
    height:0;
    visibility:hidden;
}
/* for IE6 */
*html .clearfix {
    zoom:1;
}
/* for IE7 */
*+html .clearfix {
    zoom:1;
}
/* =1 Links
---------------------------------------------------------------------------------------------------------------------*/
a{
    text-decoration:none;
}
/* a:hover{
    text-decoration: underline;
} */

/* = 2 Form
---------------------------------------------------------------------------------------------------------------------*/
button,
input[type="submit"],
input[type="reset"],
input[type="button"]{
    cursor:pointer;
}





